import tw, { styled } from "twin.macro";

export const Wrapper = styled.div`
  ${tw`
    grid
    grid-cols-1
    h-5/6
    mx-5
    my-3
    md:mx-16
    md:my-5
    lg:mx-20
    lg:my-10
   `}
`;
